<template>
  <div>
    <h5 class="text-secondary">
      {{ $t("page.condominium.editUser") }} -
      <b-badge
        class="text-white font-weight-normal"
        :variant="userStatusVariant[currentUser.status]"
      >
        {{ $t(currentUser.status) }}
      </b-badge>
    </h5>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-form>
        <b-row class="no-gutters ml-n3">
          <b-col class="col-12 col-md-6 pl-3">
            <form-input
              v-model="user.name"
              label="requiredField.name"
              veeName="name"
              :veeRules="{ required: true }"
            />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <form-input
              v-model="user.surname"
              label="requiredField.surname"
              veeName="surname"
              :veeRules="{ required: true }"
            />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <b-row no-gutters align-v="center" class="mb-2">
              <label class="font-bold mb-0"
                ><b>{{ $t("email") }}</b></label
              >
              <b-icon
                v-if="$is1Board"
                v-b-tooltip.hover
                :title="$t(user.emailPublic ? 'publicInfo' : 'privateInfo')"
                class="ml-4"
                :icon="user.emailPublic ? 'eye' : 'eye-slash'"
              ></b-icon>
            </b-row>
            <form-input v-model="user.email" :disabled="true" />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <b-row no-gutters align-v="center" class="mb-2">
              <label class="font-bold mb-0"
                ><b>{{ $t("telephone") }}</b></label
              >
              <b-icon
                v-if="$is1Board"
                v-b-tooltip.hover
                :title="$t(user.telephonePublic ? 'publicInfo' : 'privateInfo')"
                class="ml-4"
                :icon="user.telephonePublic ? 'eye' : 'eye-slash'"
              ></b-icon>
            </b-row>
            <form-input v-model="user.telephone" />
          </b-col>

          <b-col class="col-12 pl-3 py-4">
            <b-row
              align-v="center"
              class="no-gutters col-12 col-md-6 col-lg-4 pl-0"
            >
              <b-col>
                <form-select v-model="user.status" label="status">
                  <b-form-select-option key="active" value="active">
                    {{ $t("active") }}
                  </b-form-select-option>
                  <b-form-select-option key="suspended" value="suspended">
                    {{ $t("suspended") }}
                  </b-form-select-option>
                </form-select>
              </b-col>
              <b-button
                class="ml-3 mt-3"
                variant="gray-medium"
                style="height: 35px; width: 35px; padding: 0;"
                pill
                :title="$t('setPassword')"
                @click="passwordModal = true"
              >
                <b-icon icon="key-fill"></b-icon>
              </b-button>
            </b-row>
            <b-modal
              size="md"
              centered
              no-close-on-esc
              no-close-on-backdrop
              hide-footer
              v-model="passwordModal"
            >
              <template v-slot:modal-title>
                <h6 class="font-weight-bold text-secondary mb-0">
                  {{ $t("setPassword") }}
                </h6>
              </template>
              <template v-slot:default>
                <b-alert show variant="warning">{{
                  $t("setPasswordDesc")
                }}</b-alert>
                <b-form-group class="mb-3">
                  <b-form-input
                    placeholder="Password"
                    v-model="password"
                  ></b-form-input>
                </b-form-group>

                <hr class="my-3 mx-n3" />

                <b-row class="no-gutters justify-content-end">
                  <b-button
                    variant="secondary"
                    class="mr-1"
                    @click="passwordModal = false"
                  >
                    {{ $t("button.cancel") }}
                  </b-button>
                  <b-button
                    variant="danger"
                    class="text-white"
                    :disabled="password.length < 8"
                    @click="savePassword"
                  >
                    {{ $t("button.save") }}
                  </b-button>
                </b-row>
              </template>
            </b-modal>
          </b-col>

          <b-col class="col-12 col-md-6 pl-3" v-if="$is1Board">
            <label class="font-bold"><b>LinkedIn</b></label>
            <div
              v-if="user.linkedIn"
              style="max-width: 95%; overflow-x: hidden; text-overflow: ellipsis"
            >
              <a target="_blank" :href="user.linkedIn">{{ user.linkedIn }}</a>
            </div>
            <div v-else>{{ $t("noInfo") }}</div>
          </b-col>
          <b-col class="col-12 col-md-6 pl-3" v-if="$is1Board">
            <form-text-area readonly v-model="user.bio" label="Bio" />
          </b-col>
          <div class="w-100 ml-3 my-4" />
          <b-col class="col-12 col-md-6 pl-3">
            <form-input v-model="user.address" label="address" />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <form-input v-model="user.city" label="city" />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <form-input v-model="user.province" label="province" />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <form-input v-model="user.zip" label="cap" />
          </b-col>
        </b-row>
      </b-form>
      <hr class="my-3 mx-n3" />
      <b-row class="no-gutters justify-content-end">
        <b-button variant="outline-dark" class="mr-1" @click="$router.go(-1)">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button
          class="text-white"
          variant="primary"
          :disabled="invalid"
          @click="updateUser"
        >
          {{ $t("button.saveChanges") }}
        </b-button>
      </b-row>
    </validation-observer>
    <b-alert
      :show="toast"
      fade
      style="position: fixed;bottom: 20px; right: 20px;z-index: 10000;"
      variant="warning"
      solid
      @dismiss-count-down="e => (toast = e)"
    >
      <b-icon icon="clipboard" class="mr-2"></b-icon>
      {{ $t("passwordCopied") }}
    </b-alert>
    <condominium-table
      class="pt-4"
      :condominiums="condominiums"
      :fields="defaultFields"
      :sortBy="sortByDetails"
      :sortDesc="sortDescDetails"
    />
  </div>
</template>

<script>
import FormInput from "../Base/BaseFormInput";
import FormSelect from "../Base/BaseFormSelect";
import FormTextArea from "../Base/BaseFormTextarea";
import CondominiumTable from "../Condominium/CondominiumTable";
import { appMixin } from "../../mixins";
export default {
  components: {
    FormInput,
    FormSelect,
    FormTextArea,
    CondominiumTable
  },
  mounted() {
    this.initForm();
  },
  mixins: [appMixin],
  data() {
    return {
      passwordModal: false,
      password: "",
      toast: 0,

      user: {},
      defaultFields: [
        { key: "name", label: "name", sort: true },
        { key: "address", label: "address", sort: true },
        { key: "role", label: "roleInCondominium", sort: false }
      ],
      userStatusVariant: {
        active: "green",
        notverified: "cyan",
        invited: "yellow"
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["consumer/getCurrentConsumerUser"];
    },
    condominiums() {
      return this.currentUser.condominiums.map(condominium => {
        return {
          id: condominium.condominiumId._id,
          role: condominium.role,
          name: condominium.condominiumId.name,
          address: this.formatAddress(condominium.condominiumId)
        };
      });
    },
    sortByDetails: {
      get() {
        return this.$store.getters["condominium/getSortByDetails"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_BY_DETAILS", value);
      }
    },
    sortDescDetails: {
      get() {
        return this.$store.getters["condominium/getSortDescDetails"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_DESC_DETAILS", value);
      }
    }
  },
  methods: {
    formatAddress(condominium) {
      return [
        condominium.address,
        condominium.streetNumber,
        condominium.city,
        condominium.province
      ].join(", ");
    },
    initForm() {
      this.user = {
        _id: this.currentUser._id,
        status: this.currentUser.status,
        name: "",
        surname: "",
        email: this.currentUser.email,
        telephone: "",
        address: "",
        city: "",
        province: "",
        zip: ""
      };
      if (this.currentUser.profile) {
        this.user.name = this.currentUser.profile.name || "";
        this.user.surname = this.currentUser.profile.surname || "";
        this.user.telephone = this.currentUser.profile.telephone || "";
        this.user.address = this.currentUser.profile.address || "";
        this.user.city = this.currentUser.profile.city || "";
        this.user.province = this.currentUser.profile.province || "";
        this.user.zip = this.currentUser.profile.zip || "";
        this.user.bio = this.currentUser.profile.bio || "";
        this.user.linkedIn = this.currentUser.profile.linkedIn || "";
        this.user.emailPublic = this.currentUser.profile.emailPublic || false;
        this.user.telephonePublic =
          this.currentUser.profile.telephonePublic || false;
      }
    },
    async updateUser() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("user/updateUser", {
          userId: this.user._id,
          userInfo: this.getProfilePayload()
        });
        this.$router.push({ name: "ConsumerUsers" });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getProfilePayload() {
      return {
        name: this.user.name,
        surname: this.user.surname,
        email: this.user.email,
        telephone: this.user.telephone,
        address: this.user.address,
        city: this.user.city,
        province: this.user.province,
        zip: this.user.zip,
        status: this.user.status
      };
    },
    async savePassword() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("user/updateUser", {
          userId: this.user._id,
          userInfo: {
            password: this.password
          }
        });
        this.passwordModal = false;
        this.password = "";
        navigator.clipboard.writeText(this.password);
        this.toast = 50;
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET", {});
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
